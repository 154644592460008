<template>
  <div>
    <p class="mt-4 mb-2 text-2xl font-semibold">Handouts</p>
    <Button
      sml
      newTab
      leading="flaticon-pdf-1"
      href="/RMR DCA Enrollment Guide.pdf"
      class="block"
    >
      DCA Enrollment Guide
    </Button>
    <p class="mt-8 mb-2 text-2xl font-semibold">Videos</p>
    <iframe
      src="https://fast.wistia.net/embed/iframe/qm5id14mng?seo=false&playerColor=096843"
      title="Wistia video player"
      allowtransparency="true"
      frameborder="0"
      scrolling="no"
      class="p-0 m-0 border border-solid rounded wistia_embed border-neutral"
      name="wistia_embed"
      allowfullscreen
      mozallowfullscreen
      webkitallowfullscreen
      oallowfullscreen
      msallowfullscreen
    ></iframe>
    <p class="p-2 m-0 mb-4">
      Introduction to Dependent Care Assistance Programs
    </p>
  </div>
</template>

<script>
import Button from "@/components/ui/Button.vue";

export default {
  components: {
    Button
  }
};
</script>
